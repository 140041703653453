<template>
    <div>
        <div class="row">
            <b-table
                head-variant="dark"
                show-empty
                hover
                responsive
                ref="holidayTable"
                :style="'min-width:100%; min-height:400px;'"
                :class="'text-center mb-0 rounded'"
                :items="items"
                :fields="fields"
                sticky-header="690px"
                :empty-text="$t('조회된 결과가 없습니다.')"
                table-variant="light"
                select-mode="multi"
                selectable
                @row-selected="onRowSelected"
                @row-clicked="onRowClicked"
            >
                <!-- @row-clicked="onRowClicked" -->
                <template #empty="">
                    <h4>{{ $t("조회자료 없음") }}</h4>
                </template>

                <!-- <template v-slot:cell(sesnDvsnCode)="row"  v-for="(sesnDvsnCode, index) in tableCode.sesnDvsnCodes" :key="index" :value="sesnDvsnCode">

      </template> -->
                <template #cell(holiName)="row">
                    <valid-input
                        :vid="'휴일명'"
                        :inputType="'text'"
                        :inputValue.sync="row.item.holiName"
                        :rules="rules.HOLIDAY_NAME_RULE"
                        :errorMessage="validMessage.COMMON_BOTH_LENGTH_VALID_MESSAGE(1, 30)"
                        :classList="'text-left'"
                    ></valid-input>
                    <!-- <input class="form-control text-left" v-model= "row.item.holiName"/>  -->
                </template>

                <template #cell(holiTypeCode)="row">
                    <select class="form-control" ref="holiTypeCode" v-model="row.item.holiTypeCode">
                        <option v-for="(code, index) in holiTypeCodeList" :key="index" :value="code.smallCode">
                            {{ code.smallCodeName }}
                        </option>
                    </select>
                </template>

                <template #cell(baseDate)="row">
                    <!-- <date-picker v-model="row.item.baseDate"
          class="text-left"
          :config="{ format: 'YYYY-MM-DD', viewMode: 'days', locale: 'ko' }" 
          :style="{position: 'absolute', top:'0px'}"
          @dp-hide="onDatePicker"
        >
        </date-picker> -->
                    <b-form-datepicker
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :placeholder="$t('날짜를 선택하세요.')"
                        viewMode="days"
                        locale="ko"
                        v-model="row.item.baseDate"
                        class="mb-2 text-left"
                        ref="baseDate"
                    >
                    </b-form-datepicker>
                </template>

                <template #cell(holiDesc)="row">
                    <valid-input
                        :inputType="'text'"
                        :inputValue.sync="row.item.holiDesc"
                        :rules="rules.HOLIDAY_ETC_RULE"
                        :NonStatus="true"
                        :classList="'text-left'"
                    ></valid-input>

                    <!-- <input class="form-control text-left" v-model= "row.item.holiDesc"/>  -->
                </template>

                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span class="align-middle" aria-hidden="true">&check;</span>
                        <span class="sr-only align-middle">Selected</span>
                    </template>
                    <template v-else>
                        <span class="align-middle" aria-hidden="true">&nbsp;</span>
                        <span class="sr-only align-middle">Not selected</span>
                    </template>
                </template>
            </b-table>
            <div class="w-100 mt-3 text-center">
                <b-button class="mr-2" size="lg" @click="onPush">{{ $t("행 추가") }}</b-button>
                <b-button size="lg" @click="onPop">{{ $t("행제거") }}</b-button>
            </div>
        </div>
    </div>
</template>

<style>
.td-custom-tag-code {
    background-color: #343a40;
    color: white;
    width: 100px;
}

.modal-open .ui-datepicker {
    z-index: 2000 !important;
}
</style>

<script>
import backEndApi from "../../../../../api/backEndApi";
import * as rules from "@src/consts/ruleConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts";
import * as validMessage from "@src/consts/validMessageConsts";

export default {
    components: {},
    props: [],
    data() {
        return {
            fields: null,
            items: [],
            emptyText: global.xe.$t("조회자료 없음"),
            selectedItems: [],
            selectedIndex: [],
            holiTypeCodeList: null,
            initData: null,
            rules,
            popupMessages,
            validMessage,
        };
    },
    watch: {},
    created() {
        this.getHoliTypeCodeCode();
        this.setFields();
    },
    mounted() {},
    updated() {},
    methods: {
        async onSave() {
            console.log(this.items);
            if (!this.$refs.holiTypeCode.value) {
                this.alertWarning(popupMessages.HOLIDAY_DIVISION_VALID_POPUP_MESSAGE);
                return;
            } else if (!this.$refs.baseDate.value) {
                this.alertWarning(popupMessages.HOLIDAY_SEARCH_PERIOD_VALID_POPUP_MESSAGE);
                return;
            }
            console.log("holi save");
            backEndApi.calendar.insertHoliday(this.items).then(({ data }) => {
                console.log(data);
                if (data.length > 0) {
                    this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                    this.$emit("reSearch");
                } else {
                    this.alertDanger(popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE);
                }
            });
        },
        onRemove() {
            if (this.selectedItems.length == 0) {
                this.alertNoti(popupMessages.HOLIDAY_DELETE_SELECT_ERROR_POPUP_MESSAGE);
                return;
            }

            this.alertConfirmWarning(popupMessages.HOLIDAY_DELETE_POPUP_MESSAGE).then((result) => {
                if (!result.value) return;

                let param = [];

                this.selectedItems.forEach((item) => {
                    param.push(item.holiIdx);
                });

                backEndApi.calendar.deleteHoliday(param).then(({ data }) => {
                    if (data > 0) {
                        this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
                        this.selectedIndex = [];
                        this.$emit("reSearch");
                    } else {
                        this.alertDanger(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                    }
                });
            });
        },
        onRowSelected(items) {
            this.selectedItems = items;
        },
        onRowClicked(row, index) {
            row;

            if (this.selectedIndex.length > 0) {
                this.selectedIndex.forEach((data, i) => {
                    if (data == index) {
                        this.selectedIndex.splice(i, 1);
                    } else {
                        this.selectedIndex.push(index);
                    }
                });
            } else {
                this.selectedIndex.push(index);
            }
        },
        getHoliTypeCodeCode() {
            backEndApi.publicCode.getSmallCodeList("holiTypeCode").then(({ data }) => {
                if (data.length > 0) {
                    this.holiTypeCodeList = data;
                }
            });
        },
        setFields() {
            this.fields = [
                { key: "selected", label: "✓", tdClass: "align-middle" },
                { key: "holiName", label: global.xe.$t("휴일명") },
                { key: "holiTypeCode", label: global.xe.$t("휴일 구분") },
                { key: "baseDate", label: global.xe.$t("조회기간") },
                { key: "holiDesc", label: global.xe.$t("비고") },
            ];
        },
        setItems(data) {
            if (Array.isArray(data)) {
                this.initData = data;
                this.items = data;
            }
            console.log(this.items);
        },
        onPush() {
            this.selectedIndex = [];
            // 신규 행 추가
            this.items.push({
                baseDate: "",
                holiTypeCode: "",
                holiName: "",
                holiDesc: "",
            });
        },
        onPop() {
            if (!this.items[this.items.length - 1]["holiIdx"]) {
                this.items.pop();
            } else {
                this.alertNoti(popupMessages.HOLIDAY_DELETE_NEW_ITEM_DELETE_POPUP_MESSAGE);
            }

            // =======================================================
            // 선택된 행 제거
            // 신규 행만 제거
            // 기존 행 제거 시 경고창 표출 후 제거 x
            // console.log(`선택된 행 제거`);
            // let flag = false;
            // this.selectedItems.forEach(row => {
            //   if(row["holiIdx"]){
            //     flag = true;
            //     // unselectRow
            //   }
            //   if(flag){
            //     return;
            //   }
            // });

            // if(flag){
            //   this.alertNoti("신규 추가된 행만 제거할 수 있습니다.");

            //   return;
            // }else{
            //   console.log("신규 행만");
            //   this.selectedIndex.forEach(index => {
            //     // this.items.splice(index,1);
            //     delete this.items[index];
            //   });

            //   this.items.forEach((item,index) => {
            //     // this.items.splice(index,1);
            //     console.log("###################item###################");
            //     console.log(item);
            //     console.log("##########################################");
            //     if(!item){
            //       this.items.slice(index,index+1);
            //     }
            //   });

            // this.selectedIndex = [];
            // }
        },
        onDatePicker() {
            console.log("onDatePicker");
        },
    },
};
</script>
