<template>
    <div class="col-xl-12">
        <header-box :title="$t('검교정 이력 관리')" />
        <hr class="mt-3 mb-3" />

        <div class="row">
            <search-box :ref="'search'" @search:data="onSearch" />
        </div>

        <div class="bg-white">
            <grid-box :ref="'grid'" :data="items" @new:param="newModal" @modify:param="modifyModal" />
        </div>

        <b-modal ref="newModal" :hide-footer="true" :title="$t('신규 검교정 이력')">
            <new-modal
                :isResist="true"
                :serverIdx="serverIdx"
                :channelIdx="channelIdx"
                :facilityIdx="facilityIdx"
                @reSearch:param="reSearch"
            />
        </b-modal>

        <b-modal ref="modifyModal" :hide-footer="true" :title="$t('검교정 이력 수정')">
            <new-modal
                :isResist="false"
                :data="modifyItem"
                :serverIdx="serverIdx"
                :channelIdx="channelIdx"
                :facilityIdx="facilityIdx"
                @reSearch:param="reSearch"
            />
        </b-modal>
        <!--
    <b-modal ref="pointLogModal" size="xl" :hide-footer="true" :title="$t('관제점 계측 이력 조회')">
      <point-log-modal :ptAddr="ptAddr" />
    </b-modal> -->
    </div>
</template>

<script>
import headerBox from "../../component/headerBox/management/Header.vue";
import searchBox from "./ClbrtnHistSearch.vue";
import gridBox from "../../component/gridBox/management/clbrtn/ClbrtnHistGrid.vue";
import newModal from "./modal/New.vue";

export default {
    name: "ClbrnHist",
    props: [],
    components: {
        headerBox,
        searchBox,
        gridBox,
        newModal,
    },
    watch: {},
    data() {
        return {
            items: null,
            serverIdx: null,
            channelIdx: null,
            facilityIdx: null,
            modifyItem: null,
        };
    },
    created() {
        console.log("검교정 이력 관리 ClbrnHist");
    },
    mounted() {},
    methods: {
        onSearch(data) {
            console.log("##########");
            console.log(data);
            console.log("##########");
            this.items = data;
        },
        newModal(param) {
            console.log("new modal");
            this.serverIdx = param.serverIdx;
            this.channelIdx = param.channelIdx;
            this.facilityIdx = param.facilityIdx;
            this.$refs.newModal.show();
        },
        modifyModal(param) {
            console.log("modify modal");
            this.serverIdx = param.param.serverIdx;
            this.channelIdx = param.param.channelIdx;
            this.facilityIdx = param.param.facilityIdx;
            this.modifyItem = param.item;

            this.$refs.modifyModal.show();
        },
        // pointLogModal(ptAddr){
        //   this.ptAddr = ptAddr;
        //   this.$refs.pointLogModal.show();
        // },
        reSearch(param) {
            console.log(param);
            this.$refs.newModal.hide();
            this.$refs.modifyModal.hide();

            this.$refs.grid.clearDetailGridSeclected();

            this.$refs.grid.reSearch(param);
        },
    },
};
</script>

<style scoped></style>
